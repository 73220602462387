 <template lang="pug">
.page.is-form.with-cover.page__light.login
  bokeh-backdrop.page__backdrop
  .page__form.inner-gap(v-loading="submitting")
    el-form.form(
      ref="form"
      label-position="top"
      autocomplete="on"
      name="login"
      :model="form"
      :rules="rules"
      @submit.native="submit"
      @keyup.native.enter="submit")
      h1.page__title Login
      .page__description
        span.mr-2 Do not have an account?
        nuxt-link.linked(:to="{ name: 'register' }" title="Register" v-click="() => $amplitude.track('CLICK_REQUEST_ACCESS')") Request access →

      client-only
        el-form-item.boxed(
          label="Email"
          prop="email")
          el-input(v-model="form.email" name="email" autocomplete="on")

        el-form-item.boxed(label="Password" prop="password")
          el-input(type="password" v-model="form.password" name="password" autocomplete="on" show-password)

        el-form-item(prop="user_role" v-if="!isLPDomain && !isBrokerDomain")
          el-radio-group(v-model="form.user_role" name="role" autocomplete="on")
            el-radio-button(label="vendor") I'm a Vendor
            el-radio-button(label="buyer") I'm a Buyer

      el-form-item
        el-button(type="primary" @click="submit" size="large") Login

      .page__description
        span.mr-2 Unable to login?
        nuxt-link.linked(
          :to="{ name: 'reset-password' }"
          title="Reset your password"
        ) Reset your password →
</template>

<script>
import { mapGetters } from 'vuex'

import BokehBackdrop from '~/components/home/BokehBackdrop'

export default {
  name: 'Login',

  components: {
    BokehBackdrop
  },

  data () {
    return {
      submitting: false,
      form: {
        email: '',
        password: '',
        user_role: null
      },
      rules: {
        email: [
          { required: true, message: 'Please input your email' },
          { type: 'email', message: 'Please input correct email address' }
        ],
        password: [
          { max: 256, message: 'Maximum is 256 characters' },
          { required: true, message: 'Please input your password' },
          { min: 8, message: 'Password must have 8 characters minimum' }
        ],
        user_role: { required: true, message: 'Please select your role' }
      }
    }
  },

  fetch ({ redirect, store }) {
    const logged = store.getters['authentication/logged']
    if (!logged) return
    return redirect(store.getters['authentication/redirectTo'])
  },

  head () {
    return {
      title: 'Login',
      meta: [{
        hid: 'description',
        name: 'description',
        content: 'Unlike other food distribution companies, the Pod Foods platform uses a full-service, transparent, end-to-end distributor model.'
      }]
    }
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged',
      isLPDomain: 'authentication/isLPDomain',
      isBrokerDomain: 'authentication/isBrokerDomain',
      redirectTo: 'authentication/redirectTo',
      user: 'authentication/user',
      isVendor: 'authentication/isVendor'
    }),

    to () {
      let to = this.$route.query.to
      if (this.isVendor &&
        !this.isBrokerDomain &&
        !this.user?.stripe_connected &&
        !this.user?.vendor_company_ach &&
        !this.$preferences.get('$vendorFirstLogin')
      ) {
        to = 'vendors/settings/payments'
        this.$preferences.set('$vendorFirstLogin', true)
      }
      try {
        const { route } = this.$router.resolve(to)

        return route
      } catch (e) {
        return this.redirectTo
      }
    }
  },

  mounted () {
    const { get } = this.$preferences

    let userRole = null
    if (this.isLPDomain) {
      userRole = 'logistics_partner'
    } else if (this.isBrokerDomain) {
      userRole = 'broker'
    } else {
      userRole = this.$route.query?.role || get('$lastUserRole', null)
    }

    this.form = {
      email: this.$route.query?.email || get('$lastUserEmail', ''),
      user_role: userRole
    }

    if (this.to?.query?.vendor_company_id) {
      this.$preferences.set('lastVendorCompany', this.to.query.vendor_company_id)
    }

    this.$nextTick(() => {
      this.$refs.form.clearValidate()
    })
  },

  methods: {
    redirect () {
      this.$router.replace(this.to)?.catch(this.$debug.log)
    },

    submit () {
      if (this.submitting) return
      this.$refs.form.validate(valid => {
        if (!valid) return false
        this.submitting = true
        this.$store
          .dispatch('authentication/login', this.form)
          .then(this.redirect)
          .finally(() => {
            this.submitting = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  .linked {
    display: inline-block;
  }
}
</style>
